.button {

    &.icon { @apply p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white}
    &.link { @apply px-5 py-3 text-gray-900 bg-transparent hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-300;}
    &.alert { @apply px-5 py-3 rounded-md border shadow-sm hover:shadow-red-500/40 hover:shadow-xl bg-white hover:bg-red-600 hover:border-red-400 hover:text-white }

    &.editor { @apply px-3 py-1.5 rounded-md text-blue-600 hover:text-blue-800 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white }

    &.normal { @apply px-5 py-3 text-gray-900 bg-white border rounded-md shadow-sm hover:shadow-gray-400/40 hover:shadow-xl hover:bg-gray-100;}
    &.primary { @apply px-5 py-3 text-gray-100 bg-blue-600 rounded-md shadow-sm shadow-blue-500/40 hover:bg-blue-500 hover:shadow-xl hover:shadow-blue-400/40;}
    &.tertiary { @apply px-5 py-3 text-gray-100 rounded-md shadow-sm shadow-rose-500/40 bg-rose-500 hover:bg-rose-400 hover:shadow-xl hover:shadow-rose-400/40;}
    &.secondary { @apply px-5 py-3 text-gray-100 rounded-md shadow-sm shadow-fuchsia-500/40 bg-fuchsia-500 hover:bg-fuchsia-400 hover:shadow-xl hover:shadow-fuchsia-400/40; }

    &.disabled { @apply px-5 py-3 text-gray-500 bg-gray-200 rounded-md shadow-sm shadow-gray-300/40 hover:bg-gray-200 hover:shadow-sm hover:text-gray-500; }
}